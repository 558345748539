<template>
  <div class="flex flex-col items-center justify-center h-full">
    <div class="my-8 text-center p-4 opacity-60">
      <h4 class="font-medium mb-4">oops, a pixta tá bombando 🙌</h4>
      <p class="">
        estamos com instabilidade no momento<br>
        por gentileza, tente novamente em alguns minutinhos
      </p>
  
      <p class="mt-6 text-sm">
        se o problema persistir, <a class="font-medium hover:underline" href="https://wa.me/5541992700420?text=instabilidade%20na%20pixta">fale com a gente</a>
      </p>

    </div>
  </div>
</template>